@tailwind base;
@tailwind components;
@tailwind utilities;
.shopping-area {
    background: url("/src/assets/images/BG_PTO_01.svg") no-repeat;
    background-size: cover;
    background-position: center;
}

@font-face {
    font-family: 'CustomFont2Name';
    src: local('CustomFont2Name'), url('./assets/fonts/FSAlbertArabic-Bold.woff2') format('woff'), url('./assets/fonts/FSAlbertArabic-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'FS Albert Arabic';
    src: local('FS Albert Arabic'), url('./assets/fonts/FSAlbertArabic-Bold.woff') format('woff'), url('./assets/fonts/FSAlbertArabic-Bold.woff2') format('woff2'), url('./assets/fonts/FSAlbertArabic-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Albert Arabic';
    src: local('FS Albert Arabic'), url('./assets/fonts/FSAlbertArabic-Light.woff') format('woff'), url('./assets/fonts/FSAlbertArabic-Light.woff2') format('woff2') url('./assets/fonts/FSAlbertArabic-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Albert Arabic';
    src: local('FS Albert Arabic'), url('./assets/fonts/FSAlbertArabic-ExtraBold.woff2') format('woff2'), url('./assets/fonts/FSAlbertArabic-ExtraBold.woff') format('woff'), url('./assets/fonts/FSAlbertArabic-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'FS Albert Arabic';
    src: local('FS Albert Arabic'), url('./assets/fonts/FSAlbertArabic-Regular.woff2') format('woff2'), url('./assets/fonts/FSAlbertArabic-Regular.woff') format('woff'), url('./assets/fonts/FSAlbertArabic-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

html {
    font-family: "FS Albert Arabic" !important;
    line-height: 1.5
}

.slide-type-1 .slick-slide {
    /* filter: blur(10px); */
    padding: 20px 16px;
}

.slide-type-1 .slick-active .slide-content {
    display: none;
}

.slide-type-5 .slick-slide {
    filter: none;
    padding: 0;
}

.slide-type-1 .slick-current img {
    width: 100%;
}

.slide-type-1 .slick-current .slide-content {
    display: block;
}

.slide-type-2 .slick-slide {
    /* filter: blur(10px); */
    padding: 0 10px;
}

.blur-affect {
    filter: blur(10px);
}

.slide-type-2 .slick-active .slide-content {
    display: none;
}

.slide-type-2 .slick-active {
    filter: none;
    backdrop-filter: none;
}

.slide-type-2 .slick-current .slide-content {
    display: block;
}

.slick-prev:before {
    display: none;
}

.slick-next:before {
    display: none;
}

[dir="rtl"] select {
    background-position: left 0.5rem center;
}

[dir='rtl'] .slick-slide {
    float: left !important;
}

@media screen and (max-width: 767px) {
    .slide-type-1 .slick-slide {
        width: 100px;
        backdrop-filter: blur(1px);
        filter: blur(10px);
        padding: 20px 16px;
    }
    .slide-type-1 .slick-current {
        width: 300px;
        filter: none;
        padding: 0;
    }
    .slide-type-2 .slick-track {
        height: 420px;
    }
    [dir="rtl"] .slide-type-2 .slick-track {
        height: 420px;
    }
}